export function UpdateTimeIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.13147 0.262863C7.89808 0.262863 7.67425 0.355577 7.50922 0.520609C7.34419 0.685641 7.25147 0.909473 7.25147 1.14286C7.25147 1.37625 7.34419 1.60009 7.50922 1.76512C7.67425 1.93015 7.89808 2.02286 8.13147 2.02286C9.31364 2.02286 10.4693 2.37342 11.4522 3.03019C12.4351 3.68697 13.2012 4.62047 13.6536 5.71265C14.106 6.80483 14.2244 8.00664 13.9938 9.16609C13.7631 10.3255 13.1939 11.3906 12.358 12.2265C11.522 13.0624 10.457 13.6317 9.29756 13.8623C8.13811 14.0929 6.9363 13.9746 5.84412 13.5222C4.75194 13.0698 3.81844 12.3037 3.16166 11.3207C2.50489 10.3378 2.15433 9.18217 2.15433 8.00001C2.16284 7.09153 2.34517 6.19308 2.69147 5.35315L3.34976 5.81029C3.49296 5.90783 3.66221 5.96 3.83547 5.96001C4.00159 5.96038 4.16414 5.91189 4.3029 5.82058C4.44524 5.72781 4.55685 5.59488 4.62358 5.43863C4.69031 5.28238 4.70917 5.10984 4.67776 4.94286L4.22862 2.56572C4.18636 2.34456 4.05899 2.14881 3.87391 2.02058C3.68883 1.89235 3.46081 1.84187 3.2389 1.88001L0.838904 2.29715C0.670771 2.3257 0.514976 2.40375 0.391445 2.52133C0.267915 2.6389 0.182266 2.79065 0.145453 2.95717C0.10864 3.12369 0.122336 3.2974 0.18479 3.4561C0.247244 3.61479 0.355617 3.75124 0.496046 3.84801L1.20576 4.33601C0.677621 5.48568 0.40098 6.73485 0.394332 8.00001C0.394332 9.53027 0.848108 11.0262 1.69828 12.2985C2.54844 13.5709 3.75682 14.5626 5.1706 15.1482C6.58438 15.7338 8.14006 15.887 9.64092 15.5885C11.1418 15.2899 12.5204 14.5531 13.6025 13.471C14.6845 12.3889 15.4214 11.0103 15.7199 9.50945C16.0185 8.00859 15.8653 6.45291 15.2797 5.03913C14.6941 3.62535 13.7024 2.41698 12.43 1.56681C11.1576 0.716639 9.66174 0.262863 8.13147 0.262863Z"
        fill="#52557E"
      />
      <path
        d="M10.4171 10.5714C10.2842 10.5716 10.1531 10.5407 10.0343 10.4811L7.74858 9.33828C7.60617 9.26704 7.48638 9.15758 7.40262 9.02216C7.31886 8.88673 7.27443 8.73067 7.27429 8.57143V4.85714C7.27429 4.62981 7.3646 4.4118 7.52534 4.25105C7.68609 4.09031 7.90411 4 8.13143 4C8.35876 4 8.57678 4.09031 8.73753 4.25105C8.89827 4.4118 8.98858 4.62981 8.98858 4.85714V8.04229L10.8011 8.94743C10.974 9.03375 11.1127 9.17594 11.1946 9.35094C11.2765 9.52594 11.2968 9.72349 11.2524 9.91152C11.2079 10.0996 11.1012 10.2671 10.9496 10.3868C10.798 10.5066 10.6104 10.5717 10.4171 10.5714Z"
        fill="#52557E"
      />
    </svg>
  );
}
