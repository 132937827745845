import styled from 'styled-components';

export const IconButton = styled.button`
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  background: none;
  &:focus {
    outline: none;
  }
  &:disabled {
    opacity: 0.5;
  }
`;
