import React from 'react';

export function PlaceIcon() {
  return (
    <svg
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.00001 0C3.86378 0.00136102 2.77449 0.453327 1.97105 1.25676C1.16762 2.06019 0.715655 3.14949 0.714294 4.28571C0.714294 6.49457 4.25344 11.2886 4.65715 11.8286C4.69707 11.8818 4.74884 11.925 4.80835 11.9548C4.86786 11.9845 4.93348 12 5.00001 12C5.06654 12 5.13216 11.9845 5.19167 11.9548C5.25118 11.925 5.30295 11.8818 5.34287 11.8286C5.74658 11.2886 9.28572 6.49457 9.28572 4.28571C9.28436 3.14949 8.8324 2.06019 8.02896 1.25676C7.22553 0.453327 6.13623 0.00136102 5.00001 0ZM5.00001 5.57143C4.74572 5.57143 4.49714 5.49602 4.2857 5.35475C4.07427 5.21347 3.90948 5.01267 3.81216 4.77774C3.71485 4.5428 3.68939 4.28429 3.739 4.03488C3.78861 3.78548 3.91106 3.55639 4.09087 3.37658C4.27068 3.19677 4.49977 3.07431 4.74918 3.0247C4.99858 2.9751 5.2571 3.00056 5.49203 3.09787C5.72696 3.19518 5.92776 3.35998 6.06904 3.57141C6.21032 3.78284 6.28572 4.03142 6.28572 4.28571C6.28572 4.62671 6.15026 4.95373 5.90915 5.19485C5.66803 5.43597 5.341 5.57143 5.00001 5.57143Z"
        fill="#8C8C8C"
      />
    </svg>
  );
}
