import styled from 'styled-components';

export const InnerCardTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #35385d;
  border-bottom: 0.5px solid #f5f5f5;
  width: 100%;
  padding-bottom: 4px;
`;

interface InnerCardFlexBoxProps {
  direction?: 'row' | 'column';
}

export const InnerCardFlexBox = styled.div<InnerCardFlexBoxProps>`
  position: relative;
  margin-top: 8px;
  width: 100%;
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : 'row')};
  flex-wrap: wrap;
  gap: 8px;
`;

export const InnerCardFlexBoxChildren = styled.div`
  flex: 1;
  min-width: calc(48% - 4px);
`;

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
`;

export const ExpandBox = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #f5f5f5;
  width: 42px;
  height: 20px;
  border-radius: 4px;
  padding: 0 6px;
  cursor: pointer;

  :hover {
    background-color: #d9d9d9;
  }
`;

export const ExpandDot = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 7px;
  background-color: #8c8c8c;
`;

export const FlexColumnAlignCenter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #8c8c8c;
`;
