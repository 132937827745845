import React from 'react';

export const NotFound = () => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h64v64H0z" />
        <g transform="translate(0 12)">
          <ellipse cx="32" cy="33" fill="#F5F5F5" rx="32" ry="7" />
          <g stroke="#D9D9D9">
            <path
              fill="#FFF"
              d="M54.5 12.949L44.43 1.522C44.021.866 43.46.5 42.906.5H21.093c-.553 0-1.115.366-1.572 1.088L9.5 12.95v8.55h45v-8.551z"
            />
            <path
              fill="#FAFAFA"
              d="M9.5 13.5v17.637c0 1.875 1.134 3.363 2.45 3.363h40.1c1.317 0 2.45-1.487 2.45-3.363V13.5H43.84c-.917 0-1.727 1.07-1.727 2.431v.007c0 1.856-1.196 3.413-2.737 3.413H24.624c-1.542 0-2.737-1.546-2.737-3.401v-.022c0-1.36-.81-2.428-1.727-2.428H9.5z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
