export function ReturnIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M6.27084 8.20228C6.24423 8.17574 6.22312 8.14422 6.20872 8.1095C6.19431 8.07479 6.1869 8.03758 6.1869 8C6.1869 7.96242 6.19431 7.92521 6.20872 7.89049C6.22312 7.85578 6.24423 7.82425 6.27084 7.79771L12.1143 1.95086C12.2291 1.84703 12.3216 1.72094 12.3863 1.58027C12.4509 1.43959 12.4863 1.28724 12.4903 1.13248C12.4942 0.977709 12.4667 0.82375 12.4094 0.679939C12.3521 0.536127 12.2661 0.405463 12.1568 0.295875C12.0474 0.186287 11.9169 0.10006 11.7732 0.0424258C11.6295 -0.0152082 11.4756 -0.0430471 11.3209 -0.0394017C11.1661 -0.0357564 11.0137 -0.000702702 10.8729 0.0636322C10.732 0.127967 10.6058 0.220242 10.5017 0.334857L4.04912 6.78743C3.88982 6.94663 3.76345 7.13566 3.67723 7.34372C3.59101 7.55177 3.54663 7.77478 3.54663 8C3.54663 8.22521 3.59101 8.44822 3.67723 8.65628C3.76345 8.86434 3.88982 9.05337 4.04912 9.21257L10.5017 15.6651C10.6058 15.7798 10.732 15.872 10.8729 15.9364C11.0137 16.0007 11.1661 16.0358 11.3209 16.0394C11.4756 16.043 11.6295 16.0152 11.7732 15.9576C11.9169 15.8999 12.0474 15.8137 12.1568 15.7041C12.2661 15.5945 12.3521 15.4639 12.4094 15.3201C12.4667 15.1762 12.4942 15.0223 12.4903 14.8675C12.4863 14.7128 12.4509 14.5604 12.3863 14.4197C12.3216 14.2791 12.2291 14.153 12.1143 14.0491L6.27084 8.20228Z"
          fill="#35385D"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
