import styled, { css } from 'styled-components';

interface CardProps {
  noShadow?: boolean;
}

export const Card = styled.div<CardProps>`
  width: 100%;
  max-width: 312px;
  padding: 16px;
  background: #ffffff;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  ${({ noShadow = false }) =>
    noShadow
      ? css`
          border: 0.5px solid #d9d9d9;
          box-sizing: border-box;
        `
      : css`
          box-shadow: 0px 3px 12px rgba(147, 147, 147, 0.25);
        `}
  border-radius: 8px;
  margin: 0 auto;
  margin-bottom: 8px;
`;

export const CardHeader = styled.header`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 12px;
  color: #e10059;
  margin-bottom: 8px;
`;

export const CardBody = styled.div``;
