import amplitude from 'amplitude-js';

export const initAmplitude = () => {
  if (process.env.REACT_APP_API_ENV === 'production') {
    amplitude
      .getInstance()
      .init(process.env.REACT_APP_AMPLITUDE_API_KEY as string);
  }
};

export const setAmplitudeUserDevice = (installationToken: string) => {
  if (process.env.REACT_APP_API_ENV === 'production') {
    amplitude.getInstance().setDeviceId(installationToken);
  }
};

export const setAmplitudeUserId = (userId: string) => {
  if (process.env.REACT_APP_API_ENV === 'production') {
    amplitude.getInstance().setUserId(userId);
  }
};

export const setAmplitudeUserProperties = (properties: any) => {
  if (process.env.REACT_APP_API_ENV === 'production') {
    amplitude.getInstance().setUserProperties(properties);
  }
};

export const sendAmplitudeData = (eventType: string, eventProperties?: any) => {
  if (process.env.REACT_APP_API_ENV === 'production') {
    amplitude.getInstance().logEvent(eventType, eventProperties);
  }
};
