import React, { useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { useStores } from '../stores';

import { Login } from '../containers/Login';
import { OrganizationList } from '../containers/OrganizationList';
import { RestaurantList } from '../containers/RestaurantList';
import { RestaurantDetails } from '../containers/RestaurantDetails';
import { InstallApp } from '../components/InstallApp';
import { sendAmplitudeData } from '../utils/amplitude';

let deferredPrompt: any;

export function Routes() {
  const { authStore } = useStores();

  const [showInstallPrompt, setShowInstallPrompt] = useState(false);

  window.addEventListener('beforeinstallprompt', (e: any) => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt = e;
    // Update UI notify the user they can install the PWA
    showInstallPromotion();
    // Optionally, send analytics event that PWA install promo was shown.
  });

  window.addEventListener('appinstalled', () => {
    // Hide the app-provided install promotion
    hideInstallPromotion();
    // Clear the deferredPrompt so it can be garbage collected
    deferredPrompt = null;
    // Optionally, send analytics event to indicate successful install
  });

  async function handleInstallApp() {
    // Hide the app provided install promotion
    hideInstallPromotion();
    // Show the install prompt

    if (!deferredPrompt) {
      return;
    }

    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    const { outcome } = await deferredPrompt.userChoice;

    if (outcome === 'accepted') {
      sendAmplitudeData('zakgestor_user_installpwa');
    }

    // Optionally, send analytics event with outcome of user choice
    console.log(`User response to the install prompt: ${outcome}`);
    // We've used the prompt, and can't use it again, throw it away
    deferredPrompt = null;
  }

  function showInstallPromotion() {
    setShowInstallPrompt(true);
  }

  function hideInstallPromotion() {
    setShowInstallPrompt(false);
  }

  if (!authStore.isLogged) {
    return (
      <Switch>
        <Route path="/login">
          <Login />
        </Route>

        <Route path="/*" render={() => <Redirect to="/login" />} />
      </Switch>
    );
  }

  return (
    <>
      <Switch>
        <Route path="/organization/:id/restaurant/:restaurantId">
          <RestaurantDetails />
        </Route>

        <Route path="/organization/:id">
          <RestaurantList />
        </Route>

        <Route path="/">
          <OrganizationList />
        </Route>
      </Switch>
      {showInstallPrompt ? (
        <InstallApp close={hideInstallPromotion} install={handleInstallApp} />
      ) : null}
    </>
  );
}
