import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;

  svg {
    position: absolute;
    top: 37px;
    right: 16px;
  }
`;
