import * as S from './styles';

type ZakButtonGroupProps = {
  activeKey: string;
  onChange(val: any): void;
  list: { key: string; value: string }[];
  width?: string;
};

export function ZakButtonGroup(props: ZakButtonGroupProps) {
  return (
    <S.Group width={props.width}>
      {props.list.map((item) => (
        <S.Button
          key={item.key}
          isActive={props.activeKey === item.key}
          onClick={() => props.onChange(item.key)}
        >
          {item.value}
        </S.Button>
      ))}
    </S.Group>
  );
}
