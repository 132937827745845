import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: normal;
  align-items: baseline;
  .zakToggleButton {
    display: inline-block;
    padding: 12px 24px;
    font-weight: bold;
    cursor: pointer;
    &_hasActive {
      background: #e10059;
      box-shadow: 0px 1px 7px rgb(225 0 89 / 50%);
      border-radius: 8px;
      color: white;
    }
  }
`;
