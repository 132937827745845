import React from 'react';
import { useHistory } from 'react-router';
import api from '../../api';

import { useStores } from '../../stores';
import { sendAmplitudeData } from '../../utils/amplitude';

import { Button } from '../Button';
import { IconButton } from '../IconButton';
import { LogoutIcon, NextArrowIcon, UserIcon } from '../SVGs';

import * as S from './styles';

interface ZakDrawerProps {
  open?: boolean;
  children?: React.ReactNode;
  onClose?: () => void;
}

export function ZakDrawer(props: ZakDrawerProps) {
  const router = useHistory();
  const { authStore, organizationStore } = useStores();

  async function logout() {
    await api.logOut();
    authStore.logout();

    sendAmplitudeData('zakgestor_user_onlogout');

    organizationStore.changeOrgList([]);
    router.push('/login');
  }
  return (
    <>
      {props.open && (
        <S.Overlay aria-label="drawerOverlay" onClick={props.onClose} />
      )}
      <S.DrawerWrapper
        aria-label="drawerWrapper"
        aria-hidden={!props.open}
        open={props.open}
      >
        {/* trocar para icon button */}
        <div className="drawer-top-bar">
          <IconButton aria-label="closeDrawerBtn" onClick={props.onClose}>
            <NextArrowIcon />
          </IconButton>
        </div>

        <div className="drawer-body">
          <div className="drawer-user-wrapper">
            <UserIcon />
            <div>
              <p className={'user-name'}>{authStore?.user?.name}</p>
              <p className={'user-manager-role'}>{authStore?.user?.name}</p>
            </div>
          </div>
          {props.children}
        </div>
        <div className="drawer-footer">
          <Button onClick={logout} defaultSize="medium">
            <LogoutIcon />
            <span>Sair</span>
          </Button>
        </div>
      </S.DrawerWrapper>
    </>
  );
}
