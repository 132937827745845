import React from 'react';

export function ZakLogo() {
  return (
    <svg
      width="35"
      height="40"
      viewBox="0 0 35 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35 17.5C35 27.165 27.165 35 17.5 35V23.1094L20.8443 19.8085L17.5 16.5076V0C27.165 0 35 7.83502 35 17.5Z"
        fill="url(#paint0_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 40C7.83502 40 0 32.165 0 22.5C0 12.835 7.83502 5 17.5 5V16.5076L17.2623 16.273L13.7267 19.7626L17.3088 23.2981L17.5 23.1094V40Z"
        fill="url(#paint1_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="17.5"
          y1="0"
          x2="17.5"
          y2="35"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E90068" />
          <stop offset="1" stopColor="#CE0037" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="-1.38458"
          y1="17.2837"
          x2="16.6207"
          y2="31.8544"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E5E5E5" />
          <stop offset="1" stopColor="#ADADAD" />
        </linearGradient>
      </defs>
    </svg>
  );
}
