import styled from 'styled-components';

type FilterProps = {
  isActive: boolean;
};

export const Filter = styled.div<FilterProps>`
  width: 100%;
  max-width: 360px;
  height: 80vh;
  background: #ffffff;
  box-shadow: 0px -2px 12px rgba(147, 147, 147, 0.25);
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  display: ${(props) => (props.isActive ? 'block' : 'none')};

  h2 {
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    color: #262626;
    margin-top: 24px;
  }
`;

export const FilterContainer = styled.div`
  width: 170px;
  margin: 24px;

  > div {
    margin: 2rem 0;
  }
`;

export const CloseBtn = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
`;

export const AppyFiltersBtn = styled.button`
  width: 312px;
  height: 40px;
  color: #fff;
  text-align: center;
  background: #52557e;
  border-radius: 8px;
  border: 0;
  margin: 2.5rem;
`;

export const Overlay = styled.div<FilterProps>`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: ${(props) => (props.isActive ? 'block' : 'none')};
`;
