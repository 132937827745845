import React from 'react';

export function LogoutIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8366 12.3063C10.4913 11.962 10.2405 11.5346 10.1086 11.0651C10.0912 11.0045 10.0544 10.9513 10.0038 10.9137C9.9532 10.8761 9.89161 10.8562 9.82857 10.8571H6C5.24224 10.8571 4.51551 10.5561 3.9797 10.0203C3.44388 9.48449 3.14286 8.75776 3.14286 8C3.14286 7.24224 3.44388 6.51551 3.9797 5.9797C4.51551 5.44388 5.24224 5.14286 6 5.14286H9.82857C9.89084 5.14278 9.95138 5.12244 10.0011 5.08491C10.0508 5.04739 10.0869 4.99472 10.104 4.93486C10.236 4.46545 10.4867 4.03798 10.832 3.69371C10.9715 3.55388 11.1247 3.4283 11.2891 3.31886C11.3299 3.29462 11.364 3.26062 11.3883 3.21992C11.4127 3.17921 11.4265 3.13309 11.4286 3.08571V1.14286C11.4286 0.839753 11.3082 0.549062 11.0938 0.334735C10.8795 0.120408 10.5888 0 10.2857 0L1.14286 0C0.839753 0 0.549062 0.120408 0.334735 0.334735C0.120408 0.549062 0 0.839753 0 1.14286L0 14.8571C0 15.1602 0.120408 15.4509 0.334735 15.6653C0.549062 15.8796 0.839753 16 1.14286 16H10.2857C10.5888 16 10.8795 15.8796 11.0938 15.6653C11.3082 15.4509 11.4286 15.1602 11.4286 14.8571V12.9143C11.4284 12.8668 11.4164 12.8201 11.3937 12.7784C11.3709 12.7366 11.3381 12.7013 11.2983 12.6754C11.1325 12.568 10.9778 12.4444 10.8366 12.3063Z"
        fill="#35385D"
      />
      <path
        d="M15.7486 8.60573C15.8282 8.52623 15.8913 8.43181 15.9344 8.32787C15.9775 8.22394 15.9997 8.11253 15.9997 8.00002C15.9997 7.8875 15.9775 7.77609 15.9344 7.67216C15.8913 7.56822 15.8282 7.4738 15.7486 7.3943L13.4629 5.10859C13.3004 4.95718 13.0855 4.87476 12.8634 4.87867C12.6413 4.88259 12.4295 4.97255 12.2724 5.12959C12.1154 5.28663 12.0254 5.4985 12.0215 5.72056C12.0176 5.94262 12.1 6.15753 12.2514 6.32002L13.0731 7.14287H6C5.77267 7.14287 5.55465 7.23318 5.3939 7.39392C5.23316 7.55467 5.14285 7.77269 5.14285 8.00002C5.14285 8.22734 5.23316 8.44536 5.3939 8.60611C5.55465 8.76685 5.77267 8.85716 6 8.85716H13.0731L12.2514 9.68002C12.1672 9.75849 12.0997 9.85312 12.0528 9.95826C12.006 10.0634 11.9808 10.1769 11.9787 10.292C11.9767 10.4071 11.9979 10.5214 12.041 10.6281C12.0841 10.7349 12.1483 10.8318 12.2297 10.9132C12.3111 10.9946 12.408 11.0588 12.5147 11.1019C12.6215 11.145 12.7358 11.1662 12.8509 11.1641C12.966 11.1621 13.0795 11.1369 13.1846 11.0901C13.2898 11.0432 13.3844 10.9757 13.4629 10.8914L15.7486 8.60573Z"
        fill="#35385D"
      />
    </svg>
  );
}
