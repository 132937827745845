import styled from 'styled-components';

type ButtonProps = {
  size: 'small' | 'medium';
};

export const Button = styled.button<ButtonProps>`
  width: ${(props) => (props.size === 'small' ? '148px' : '312px')};
  height: 40px;
  background: #262626;
  border-radius: 8px;
  border: 0;

  &:disabled {
    opacity: 0.5;
  }
`;
