import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 312px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(151, 151, 151, 0.25);
  position: relative;

  input {
    width: 100%;
    border: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    padding: 12px 4px 12px 16px;
    border-radius: 8px;
    color: #262626;

    &:focus {
      outline: none;
      box-shadow: 0px 4px 12px rgba(133, 30, 91, 0.25);
    }
  }

  svg {
    position: absolute;
    top: 13px;
    right: 24px;
  }
`;
