import React from 'react';

import { SearchIcon } from '../SVGs';

import * as S from './styles';

export type ZackSearchInputProps = React.InputHTMLAttributes<HTMLInputElement>;

export function ZakSearchInput(props: ZackSearchInputProps) {
  return (
    <S.Wrapper>
      <input
        type="search"
        placeholder="Search..."
        aria-disabled={props.disabled}
        {...props}
      />
      <SearchIcon />
    </S.Wrapper>
  );
}
