import { InputHTMLAttributes } from 'react';

import * as S from './styles';

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  error?: string;
  defaultSize: 'small' | 'medium';
};

export function InputWithLabel(props: InputProps) {
  const { label, error, defaultSize, ...restProps } = props;
  return (
    <S.Wrapper error={Boolean(error)} size={defaultSize}>
      {label ? (
        <label
          htmlFor={`label-${label}`}
          aria-label={label}
          aria-required="true"
        >
          {label}
        </label>
      ) : null}

      <input {...restProps} />

      <span>{error}</span>
    </S.Wrapper>
  );
}
