import { ZakPageHeaderLayout } from '../../components/ZakPageHeaderLayout';
import { useParams } from 'react-router';
import {
  RestaurantDetailCard,
  RestaurantDetailCardProps
} from './RestaurantDetailCard';
import {
  LabelSizes,
  ValueIndicatorLabels
} from '../../components/ZakCardValueIndicator/styles';
import { useEffect, useMemo, useState } from 'react';
import api from '../../api';
import { compareVersion, formatToMoney } from '../../utils/helpers';
import { notification } from '../../utils/notification';
import { CardLoader } from '../../components/ContentLoader';
import { useStores } from '../../stores';
import { Filters } from './Filters';

import * as S from './styles';
import { UrlParams } from '../../types/UrlParams';
import { useFetchManagementGeneralInformation } from '../../hooks/useFetchManagementGeneralInformation';
import { useFetchReportProductSoldWithDiscountsAndTips } from '../../hooks/useFetchReportProductSoldWithDiscountsAndTips';
import { sendAmplitudeData } from '../../utils/amplitude';

export function RestaurantDetails() {
  const { organizationStore, authStore } = useStores();
  const [lastUpdate, setLastUpdate] = useState<Date>();
  const params = useParams<UrlParams>();

  const [loading, setLoading] = useState(true);
  const [filtersIsOpen, setFiltersIsOpen] = useState(false);
  const [revenueReportData, setRevenueReportData] = useState<
    RestaurantDetailCardProps[]
  >([]);
  const {
    managementGeneralInformation,
    getManagementGeneralInformationV2FromApi,
    isLoading: managementGeneralInformationIsLoading
  } = useFetchManagementGeneralInformation();
  const {
    reportProductSoldWithDiscountsAndTips,
    getReportProductSoldWithDiscountsAndTipsFromApi,
    isLoading: reportProductSoldWithDiscountsAndTipsIsLoading
  } = useFetchReportProductSoldWithDiscountsAndTips();

  const newReportCard = (
    title: string,
    label?: string,
    value?: string | number,
    labelSize: LabelSizes = LabelSizes.medium
  ) => ({
    title,
    valueIndicator: {
      label,
      value: typeof value === 'number' ? value.toString() : value,
      labelSize
    }
  });

  const findPercentage = (totalValue: number, partialValue: number) => {
    return (partialValue / (totalValue / 100)).toFixed(2);
  };

  const parseRevenueReport = (revenueReport: api.RevenueReportSummary) => {
    const reportParsedData: RestaurantDetailCardProps[] = [
      {
        ...newReportCard(
          'Faturamento Liquido',
          ValueIndicatorLabels.money,
          formatToMoney(
            revenueReport.tableRevenue + revenueReport.deliveryRevenue
          )
        ),
        innerCards: [
          newReportCard(
            'Salão',
            ValueIndicatorLabels.money,
            formatToMoney(revenueReport.tableRevenue)
          ),
          newReportCard(
            'Delivery',
            ValueIndicatorLabels.money,
            formatToMoney(revenueReport.deliveryRevenue)
          )
        ]
      },
      {
        ...newReportCard('Clientes e Pedidos'),
        innerCards: [
          newReportCard(
            'Salão',
            ValueIndicatorLabels.total,
            revenueReport.numberOfPeople
          ),
          newReportCard(
            'Delivery',
            ValueIndicatorLabels.total,
            revenueReport.numberOfDeliveryOrders
          )
        ]
      },
      {
        ...newReportCard('Ticket Médio'),
        innerCards: [
          newReportCard(
            'Salão',
            ValueIndicatorLabels.money,
            formatToMoney(revenueReport.tableTicketAverage)
          ),
          newReportCard(
            'Delivery',
            ValueIndicatorLabels.money,
            formatToMoney(revenueReport.deliveryTicketAverage)
          )
        ]
      }
    ];
    return reportParsedData;
  };

  const parsedTipReport = useMemo<RestaurantDetailCardProps[]>(
    () => [
      {
        ...newReportCard('Total de Gorjeta'),
        innerCards: [
          newReportCard(
            '',
            ValueIndicatorLabels.money,
            formatToMoney(managementGeneralInformation?.table.tip)
          )
        ]
      },
      {
        ...newReportCard('Total de Desconto'),
        innerCards: [
          newReportCard(
            '',
            ValueIndicatorLabels.money,
            formatToMoney(managementGeneralInformation?.table.discounts)
          )
        ]
      },
      {
        ...newReportCard('Repique'),
        innerCards: [
          newReportCard(
            'Salão',
            ValueIndicatorLabels.money,
            formatToMoney(managementGeneralInformation?.table.extraTip)
          ),
          newReportCard(
            'Delivery',
            ValueIndicatorLabels.money,
            formatToMoney(managementGeneralInformation?.delivery.extraTip)
          )
        ]
      }
    ],
    [managementGeneralInformation]
  );

  const getCategories = useMemo<RestaurantDetailCardProps[]>(() => {
    if (!reportProductSoldWithDiscountsAndTips) return [];

    let categories = reportProductSoldWithDiscountsAndTips?.categories
      .filter(
        (category) => !['DESCONTO_00', 'GORJETA_00'].includes(category.id)
      )
      .sort((c1, c2) => c2.totalValue - c1.totalValue);

    let sumOfTotalValues = categories.reduce(
      (acc, cur) => acc + cur.totalValue,
      0
    );

    return categories.map((category) =>
      newReportCard(
        category.name,
        ValueIndicatorLabels.money,
        `${formatToMoney(category.totalValue)} (${findPercentage(
          sumOfTotalValues,
          category.totalValue
        )}%)`
      )
    );
  }, [reportProductSoldWithDiscountsAndTips]);

  const getSubCategories = useMemo<RestaurantDetailCardProps[]>(() => {
    if (!reportProductSoldWithDiscountsAndTips) return [];

    let subcategories = reportProductSoldWithDiscountsAndTips?.categories
      .map((c) => c.subcategories)
      .reduce((p, c) => p.concat(c))
      .sort((sc1, sc2) => sc2.totalValue - sc1.totalValue);

    let sumOfTotalValues = subcategories.reduce(
      (acc, cur) => acc + cur.totalValue,
      0
    );

    return subcategories.map((sc) =>
      newReportCard(
        sc.name,
        ValueIndicatorLabels.money,
        `${formatToMoney(sc.totalValue)} (${findPercentage(
          sumOfTotalValues,
          sc.totalValue
        )}%)`
      )
    );
  }, [reportProductSoldWithDiscountsAndTips]);

  const categoryAndSubcategoryReport = [
    {
      ...newReportCard('Faturamento por Categoria'),
      innerCards: getCategories
    },
    {
      ...newReportCard('Faturamento por Subcategoria'),
      innerCards: getSubCategories
    }
  ];

  const currentRestaurantTitle = useMemo(() => {
    const rest = organizationStore.currentOrg?.restaurants.find(
      ({ id }) => id === params.restaurantId
    );

    return rest ? rest.info.name : 'restaurant title';
  }, [params.restaurantId, organizationStore.currentOrg]);

  const getRevenueReportFromAPI = async (since: Date, until: Date) => {
    // gerencial é o padrão
    try {
      setLoading(true);
      const version = await api.getRestaurantVersion(params.restaurantId);
      if (compareVersion(version.version, '4.0.0') >= 0) {
        const revenueReport = await api.getRevenueReportSummary(
          params.restaurantId,
          organizationStore.shiftMode,
          since,
          until
        );

        setRevenueReportData(parseRevenueReport(revenueReport));
      }
    } catch (error) {
      notification((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  function applyFilters() {
    const since = new Date();
    const until = new Date();
    if (organizationStore.rangeDateDefaults === 'yesterday') {
      since.setDate(since.getDate() - 1);
      until.setDate(until.getDate() - 1);
    } else if (organizationStore.rangeDateDefaults === 'last7Days') {
      since.setDate(since.getDate() - 7);
    } else if (organizationStore.rangeDateDefaults === 'last14Days') {
      since.setDate(since.getDate() - 14);
    } else if (organizationStore.rangeDateDefaults === 'last30Days') {
      since.setDate(since.getDate() - 30);
    }

    getRevenueReportFromAPI(since, until);
    getManagementGeneralInformationV2FromApi(
      organizationStore.shiftMode,
      since,
      until
    );
    getReportProductSoldWithDiscountsAndTipsFromApi(
      organizationStore.shiftMode,
      null,
      since,
      until,
      { categoryId: null, subCategoryId: null, productId: null }
    );
  }

  useEffect(() => {
    const dateFilter = new Date();

    if (organizationStore.rangeDateDefaults === 'yesterday') {
      dateFilter.setDate(dateFilter.getDate() - 1);
    }

    getRevenueReportFromAPI(dateFilter, dateFilter);
    getManagementGeneralInformationV2FromApi(
      organizationStore.shiftMode,
      dateFilter,
      dateFilter
    );

    getReportProductSoldWithDiscountsAndTipsFromApi(
      organizationStore.shiftMode,
      null,
      dateFilter,
      dateFilter,
      { categoryId: null, subCategoryId: null, productId: null }
    );

    getLastVersion(params.restaurantId).then((res) => {
      console.log(res);
      setLastUpdate(res);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function detectOnScrollReachedBotom(e: any) {
    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
      sendAmplitudeData('zakgestor_user_reachedbottom_restaurantdetail');
    }
  }

  async function getLastVersion(restaurantId: string) {
    return (await api.getLastVersion(restaurantId)) ?? undefined;
  }

  return (
    <ZakPageHeaderLayout
      onScroll={detectOnScrollReachedBotom}
      pageTitleProps={{
        pushBackUrl: `/organization/${params.id}`,
        title: currentRestaurantTitle // puxar esses dados de algum lugar
      }}
      lastUpdatedIndicatorProps={{
        reload: () => getRevenueReportFromAPI(new Date(), new Date()),
        // podemos utilizar dps uma função de formatação de data adequada
        lastUpdate: lastUpdate
          ? `${lastUpdate?.toLocaleDateString('pt-BR', {
              timeZone: 'Etc/GMT-0'
            })} ${lastUpdate?.toLocaleTimeString('pt-BR', {
              timeZone: 'Etc/GMT-0'
            })}`
          : 'Não disponível'
      }}
      genericHeaderComponent={
        <S.FilterBtn onClick={() => setFiltersIsOpen(true)}>
          Filtros
        </S.FilterBtn>
      }
    >
      {authStore.hasPermission ? (
        <>
          {loading ||
          managementGeneralInformationIsLoading ||
          reportProductSoldWithDiscountsAndTipsIsLoading ? (
            <CardLoader size={7} height={140} gap={12} />
          ) : (
            <>
              {revenueReportData.map((restaurantDetailCardProps, index) => (
                <RestaurantDetailCard
                  {...restaurantDetailCardProps}
                  key={index}
                />
              ))}
              {parsedTipReport.map((restaurantDetailCardProps, index) => (
                <RestaurantDetailCard
                  {...restaurantDetailCardProps}
                  key={index}
                />
              ))}

              {categoryAndSubcategoryReport.map(
                (restaurantDetailCardProps, index) => (
                  <RestaurantDetailCard
                    direction="column"
                    {...restaurantDetailCardProps}
                    key={index}
                  />
                )
              )}
            </>
          )}

          <Filters
            rangeDateDefaults={organizationStore.rangeDateDefaults}
            changeRangeDateDefaults={organizationStore.changeRangeDateDefaults}
            closeFilter={() => setFiltersIsOpen(false)}
            isOpen={filtersIsOpen}
            shiftMode={organizationStore.shiftMode}
            changeShiftMode={organizationStore.changeShiftMode}
            applyFilters={applyFilters}
          />
        </>
      ) : (
        <h1>Acesso Negado!</h1>
      )}
    </ZakPageHeaderLayout>
  );
}
