import * as api from '@cubos/zak-manager-api';

const apiEndpoints: { [key: string]: string } = {
  production: 'api.zakpay.io/manager',
  development: 'zak-api.development.mimic.com.br/manager',
  staging: 'zak-api-staging.development.mimic.com.br/manager'
};

api.setBaseUrl(apiEndpoints[process.env.REACT_APP_API_ENV as string]);

export default api;
