import { useEffect } from 'react';
import { Routes } from './routes';

export function App() {
  useEffect(() => {
    const urlToRedirect = 'gestor.zakpay.io';

    if (window.location.href.includes(urlToRedirect)) {
      window.location.replace('https://gestor.zak.app/');
    }
    console.log(
      `
%cWWWWWWWWWWWWWW@===#@WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW

WWWWWWWWWWWWWW@=======#WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW#:@WWWWWWW

WWWWWWWWW@*+:-:*========#WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW=-#WWWWWWW

WWWWWW#:------:*==========@WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW=-#WWWWWWW

WWWW#:-------::============@WWWWWWWW@:.........:WWWWW@*-.--.-*@WWWWWW=-#WWWWW#:

WWW*-------::::============#WWWWWWWWWWWWWWWW=-=WWWWW+-=WWWWWW=-+WWWWW=-#WWW=-+@

WW=-------::::=#============WWWWWWWWWWWWWW@::@WWWWW+-@WWWWWWWW@-*WWWW=-#W*-*WWW

W@:-----::::=WWWW==========#WWWWWWWWWWWWW*-#WWWWWWW:+WWWWWWWWWW::WWWW=.:-:-=WWW

W@:----::::::*@@===========@WWWWWWWWWWW#-+WWWWWWWWW+-@WWWWWWWW@-:WWWW=.=WW@::@W

W@:--:::::::::+===========#WWWWWWWWWWW+-#WWWWWWWWWWW*-*WWWWW@*--:WWWW=-#WWWW=-*

WW*:::::::::::+==========#WWWWWWWWWW@:.--------+WWWWWW=:----:=W++WWWW#-@WWWWWW+

WWW+::::::::::+========#WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW

WWWW*::::::::++=====#@WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW

WWWWW@*::::+++*@WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW

WWWWWWWW@=++++*WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW
`,
      'color: #ee3162'
    );
  }, []);
  return <Routes />;
}
