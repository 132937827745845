import React from 'react';

export function MainMenuIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M7.14284 7.14286C7.21861 7.14286 7.29129 7.11275 7.34487 7.05917C7.39845 7.00559 7.42855 6.93292 7.42855 6.85714V0.285714C7.42855 0.209938 7.39845 0.137266 7.34487 0.0836838C7.29129 0.030102 7.21861 0 7.14284 0H2.50741C1.91836 0.000605085 1.35361 0.234872 0.937087 0.651393C0.520566 1.06791 0.286299 1.63266 0.285694 2.22171V6.85714C0.285694 6.93292 0.315796 7.00559 0.369378 7.05917C0.42296 7.11275 0.495632 7.14286 0.571408 7.14286H7.14284Z"
          fill="#52557E"
        />
        <path
          d="M8.85718 6.85714C8.85718 6.93292 8.88728 7.00559 8.94086 7.05917C8.99444 7.11275 9.06712 7.14286 9.14289 7.14286H15.7143C15.7901 7.14286 15.8628 7.11275 15.9164 7.05917C15.9699 7.00559 16 6.93292 16 6.85714V2.22171C15.9994 1.63266 15.7652 1.06791 15.3486 0.651393C14.9321 0.234872 14.3674 0.000605085 13.7783 0L9.14289 0C9.06712 0 8.99444 0.030102 8.94086 0.0836838C8.88728 0.137266 8.85718 0.209938 8.85718 0.285714V6.85714Z"
          fill="#52557E"
        />
        <path
          d="M9.14289 8.85715C9.06712 8.85715 8.99444 8.88725 8.94086 8.94083C8.88728 8.99441 8.85718 9.06709 8.85718 9.14286V15.7143C8.85718 15.7901 8.88728 15.8627 8.94086 15.9163C8.99444 15.9699 9.06712 16 9.14289 16H13.7783C14.3674 15.9994 14.9321 15.7651 15.3486 15.3486C15.7652 14.9321 15.9994 14.3673 16 13.7783V9.14286C16 9.06709 15.9699 8.99441 15.9164 8.94083C15.8628 8.88725 15.7901 8.85715 15.7143 8.85715H9.14289Z"
          fill="#52557E"
        />
        <path
          d="M7.14286 9.14286C7.14286 9.06709 7.11275 8.99441 7.05917 8.94083C7.00559 8.88725 6.93292 8.85715 6.85714 8.85715H0.285714C0.209938 8.85715 0.137266 8.88725 0.0836838 8.94083C0.030102 8.99441 0 9.06709 0 9.14286L0 13.7783C0.000605085 14.3673 0.234872 14.9321 0.651393 15.3486C1.06791 15.7651 1.63266 15.9994 2.22171 16H6.85714C6.93292 16 7.00559 15.9699 7.05917 15.9163C7.11275 15.8627 7.14286 15.7901 7.14286 15.7143V9.14286Z"
          fill="#52557E"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
