import { useState } from 'react';
import { NotFound } from '../../../components/SVGs/NotFound';
import { ZakCardBox } from '../../../components/ZakCardBox';
import {
  ZakCardValueIndicator,
  ZakCardValueIndicatorProps
} from '../../../components/ZakCardValueIndicator';
import {
  ExpandBox,
  ExpandDot,
  FlexCenter,
  FlexColumnAlignCenter,
  InnerCardFlexBox,
  InnerCardFlexBoxChildren,
  InnerCardTitle
} from './styles';

interface DetailCardProps {
  title?: string;
  valueIndicator?: ZakCardValueIndicatorProps;
  noShadow?: boolean;
  direction?: 'row' | 'column';
}

export interface RestaurantDetailCardProps extends DetailCardProps {
  innerCards?: DetailCardProps[];
}

export const RestaurantDetailCard: React.FC<RestaurantDetailCardProps> = ({
  title,
  valueIndicator,
  innerCards,
  direction
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  function expandedToggle() {
    setExpanded(!expanded);
  }

  const containerDirection = direction
    ? direction
    : innerCards && innerCards.length === 2
    ? 'row'
    : 'column';
  const greaterThanThree = innerCards && innerCards.length > 3;
  const noData = !innerCards || innerCards?.length === 0;

  return (
    <ZakCardBox cardTitle={<h3>{title}</h3>}>
      {valueIndicator && <ZakCardValueIndicator {...valueIndicator} />}
      <InnerCardFlexBox direction={containerDirection}>
        {noData && (
          <FlexColumnAlignCenter>
            <NotFound />
            <div>Sem Dados</div>
          </FlexColumnAlignCenter>
        )}
        {innerCards
          ?.filter((_, key) => (key < 3 ? true : expanded))
          .map((innerCard, key) => (
            <InnerCardFlexBoxChildren key={key}>
              <ZakCardBox
                noShadow
                cardTitle={
                  innerCard.title && (
                    <InnerCardTitle>
                      <span>{innerCard.title}</span>
                    </InnerCardTitle>
                  )
                }
              >
                {innerCard.valueIndicator && (
                  <ZakCardValueIndicator {...innerCard.valueIndicator} />
                )}
              </ZakCardBox>
            </InnerCardFlexBoxChildren>
          ))}
        {greaterThanThree && (
          <FlexCenter>
            <ExpandBox role="expand-box" onClick={expandedToggle}>
              <ExpandDot />
              <ExpandDot />
              <ExpandDot />
            </ExpandBox>
          </FlexCenter>
        )}
      </InnerCardFlexBox>
    </ZakCardBox>
  );
};
