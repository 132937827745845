import { ReactNode } from 'react';

import * as S from './styles';

interface ZakCardBoxProps {
  noShadow?: boolean;
  cardTitle?: React.ReactNode;
  onClick?: () => void;
  children?: ReactNode;
}

export function ZakCardBox(props: ZakCardBoxProps) {
  return (
    <S.Card onClick={props.onClick} noShadow={props.noShadow}>
      {props.cardTitle && <S.CardHeader>{props.cardTitle}</S.CardHeader>}
      <S.CardBody>{props.children}</S.CardBody>
    </S.Card>
  );
}
