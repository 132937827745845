import { ButtonHTMLAttributes } from 'react';

import * as S from './styles';

type IconButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

export function IconButton(props: IconButtonProps) {
  const { children, ...restProps } = props;
  return <S.IconButton {...restProps}>{children}</S.IconButton>;
}
