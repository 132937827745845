import { CloseIcon } from '../SVGs/CloseIcon';
import * as S from './styles';

type IntallAppProps = {
  close(): void;
  install(): void;
};

export function InstallApp(props: IntallAppProps) {
  return (
    <S.InstallApp>
      <S.Close onClick={props.close}>
        <CloseIcon fill="#fff" />
      </S.Close>
      <h2>
        Acesse os dados do seu restaurante de maneira prática usando nosso web
        app.
      </h2>
      <button onClick={props.install}>Instalar</button>
    </S.InstallApp>
  );
}
