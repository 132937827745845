import React from 'react';

export function UserIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.0051995 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9964 8.81852 22.7309 5.76838 20.4813 3.51873C18.2316 1.26908 15.1815 0.00362976 12 0V0ZM12 5.14286C12.6781 5.14286 13.341 5.34394 13.9048 5.72068C14.4686 6.09741 14.9081 6.63288 15.1676 7.25937C15.4271 7.88586 15.495 8.57523 15.3627 9.24031C15.2304 9.90539 14.9039 10.5163 14.4244 10.9958C13.9449 11.4753 13.334 11.8018 12.6689 11.9341C12.0038 12.0664 11.3144 11.9985 10.6879 11.739C10.0615 11.4795 9.52599 11.0401 9.14925 10.4762C8.77252 9.91241 8.57143 9.24954 8.57143 8.57143C8.57143 7.66212 8.93266 6.79004 9.57564 6.14706C10.2186 5.50408 11.0907 5.14286 12 5.14286ZM17.3777 18.552C15.8882 19.8549 13.9764 20.5731 11.9974 20.5731C10.0185 20.5731 8.1067 19.8549 6.61715 18.552C6.53204 18.4778 6.47949 18.3732 6.47083 18.2606C6.46217 18.1481 6.4981 18.0366 6.57086 17.9503C7.23756 17.1607 8.06854 16.5261 9.0058 16.0908C9.94306 15.6555 10.964 15.43 11.9974 15.43C13.0308 15.43 14.0518 15.6555 14.9891 16.0908C15.9263 16.5261 16.7573 17.1607 17.424 17.9503C17.4963 18.0368 17.532 18.1481 17.5233 18.2606C17.5147 18.373 17.4624 18.4776 17.3777 18.552Z"
        fill="#E10059"
      />
    </svg>
  );
}
