import styled from 'styled-components';

export const zakDrawerAttributes = {
  width: '196px',
  translateX: '206px'
};

interface DrawerWrapperProps {
  open?: boolean;
}

const drawerWrapperTranslateX = (open?: boolean) =>
  open ? '0' : zakDrawerAttributes.translateX;

export const DrawerWrapper = styled.aside<DrawerWrapperProps>`
  position: fixed;
  width: ${zakDrawerAttributes.width};
  height: 100vh;
  right: 0;
  top: 0px;
  z-index: 2;
  border-top-left-radius: 12px;
  transform: translateX(${({ open }) => drawerWrapperTranslateX(open)});
  /* gradient */
  background: linear-gradient(360deg, #35385d 0%, #52557e 100%);
  box-shadow: -4px 0px 12px rgba(151, 151, 151, 0.25);

  /* transition */
  transition: transform 0.4s;

  .drawer-top-bar {
    padding-top: 16px;
    padding-left: 16px;
  }
  .drawer-user-wrapper {
    display: flex;
    align-items: center;
    & > div {
      color: #ffffff;
      margin-left: 8px;
      & > .user-name {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
      }
      & > .user-manager-role {
        font-weight: 300;
        font-size: 10px;
        line-height: 14px;
      }
    }
  }
  .drawer-body {
    padding: 24px;
  }
  .drawer-footer {
    position: absolute;
    bottom: 0px;
    padding: 40px 24px;
    width: 100%;
    & > button {
      width: 100%;
      background-color: #ffffff;
      text-align: left;
      padding: 12px 24px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 8px;
      }
    }
  }
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
`;
