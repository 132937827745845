import { ReactNode, useState } from 'react';
import { useHistory } from 'react-router';

import { ZakAppBar } from '../ZakAppBar';
import { ZakDrawer } from '../ZakDrawer';
import { ZakPageTitle, ZakPageTitleProps } from '../ZakPageTitle';
import { ZakSearchInput, ZackSearchInputProps } from '../ZakSearchInput';
import {
  ZakLastUpdatedIndicator,
  ZakLastUpdatedIndicatorProps
} from '../ZakLastUpdatedIndicator';

import * as S from './styles';

interface ZakPageHeaderLayoutProps {
  searchInputProps?: ZackSearchInputProps;
  pageTitleProps?: ZakPageTitleProps;
  lastUpdatedIndicatorProps?: ZakLastUpdatedIndicatorProps;
  children: ReactNode;
  genericHeaderComponent?: ReactNode;
  onScroll?(e: React.UIEvent<HTMLDivElement, UIEvent>): void;
}

export function ZakPageHeaderLayout(props: ZakPageHeaderLayoutProps) {
  const [scrolling, setScrolling] = useState(false);
  const router = useHistory();
  const [drawerOpen, setdrawerOpen] = useState(false);

  const scrollHandler = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const element = e.currentTarget;
    setScrolling(element.scrollTop > 0);
    if (props.onScroll) {
      props.onScroll(e);
    }
  };

  return (
    <S.Wrapper>
      <S.Header isScrolling={scrolling ?? false}>
        <ZakAppBar
          onClickMainMenu={
            router && router.location.pathname !== '/'
              ? () => router.push('/')
              : undefined
          }
          onClickDrawer={() => setdrawerOpen((oldState) => !oldState)}
          onClickNotification={() => console.log('notification')}
        />

        {props.searchInputProps && (
          <ZakSearchInput {...props.searchInputProps} />
        )}

        {props.pageTitleProps && <ZakPageTitle {...props.pageTitleProps} />}

        {props.genericHeaderComponent ? props.genericHeaderComponent : null}

        {props.lastUpdatedIndicatorProps && (
          <ZakLastUpdatedIndicator {...props.lastUpdatedIndicatorProps} />
        )}
      </S.Header>

      <ZakDrawer
        onClose={() => setdrawerOpen((oldState) => !oldState)}
        open={drawerOpen}
      />

      <S.Body onScroll={scrollHandler}>{props.children}</S.Body>
    </S.Wrapper>
  );
}
