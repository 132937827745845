import { LabelSizes } from './styles';

import * as S from './styles';

export interface ZakCardValueIndicatorProps {
  label?: string;
  labelSize?: LabelSizes;
  value?: string;
  loading?: boolean;
}

export function ZakCardValueIndicator(props: ZakCardValueIndicatorProps) {
  return (
    <S.Wrapper>
      {props.loading ? (
        <S.MoneyLoader />
      ) : (
        <>
          {props.label && (
            <S.Label size={props.labelSize}>{props.label}</S.Label>
          )}
          {props.value && <S.Value>{props.value}</S.Value>}
        </>
      )}
    </S.Wrapper>
  );
}
