import React from 'react';

export function SearchIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M11.7489 11.7488C11.9095 11.5881 11.9998 11.3701 11.9998 11.1428C11.9998 10.9156 11.9095 10.6976 11.7489 10.5368L9.45943 8.24742C9.424 8.21284 9.40159 8.1671 9.39597 8.11791C9.39035 8.06872 9.40186 8.01911 9.42857 7.97742C10.1317 6.91325 10.4146 5.62594 10.2225 4.36502C10.0305 3.1041 9.3771 1.9594 8.38906 1.15282C7.40102 0.346243 6.1487 -0.0647653 4.87486 -0.000527948C3.60101 0.0637095 2.39639 0.598617 1.49451 1.5005C0.59262 2.40239 0.0577128 3.60701 -0.00652465 4.88085C-0.0707621 6.15469 0.340247 7.40701 1.14683 8.39505C1.95341 9.3831 3.09811 10.0365 4.35902 10.2285C5.61994 10.4206 6.90726 10.1377 7.97143 9.43456C8.01273 9.40743 8.06213 9.39534 8.1113 9.40034C8.16047 9.40533 8.20642 9.4271 8.24143 9.46199L10.5309 11.7514C10.6107 11.8317 10.7057 11.8954 10.8103 11.9388C10.915 11.9821 11.0271 12.0043 11.1404 12.0041C11.2536 12.0039 11.3657 11.9812 11.4702 11.9374C11.5746 11.8936 11.6693 11.8295 11.7489 11.7488ZM1.71428 5.14285C1.71428 4.46474 1.91537 3.80186 2.2921 3.23804C2.66884 2.67421 3.20431 2.23476 3.8308 1.97526C4.45729 1.71576 5.14666 1.64787 5.81174 1.78016C6.47681 1.91245 7.08773 2.23899 7.56722 2.71849C8.04672 3.19798 8.37326 3.80889 8.50555 4.47397C8.63784 5.13905 8.56994 5.82842 8.31044 6.45491C8.05094 7.0814 7.61149 7.61687 7.04767 7.9936C6.48384 8.37034 5.82096 8.57142 5.14286 8.57142C4.23354 8.57142 3.36147 8.2102 2.71849 7.56722C2.07551 6.92423 1.71428 6.05216 1.71428 5.14285Z"
          fill="#8C8C8C"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
