import ContentLoader from 'react-content-loader';

type CardLoaderProps = {
  height: number;
  size: number;
  gap?: number;
};

export function CardLoader({ height, size, gap: defaultGap }: CardLoaderProps) {
  const gap = defaultGap ?? 5;

  return (
    <ContentLoader
      speed={2}
      width={312}
      height={height * size + size * gap}
      viewBox={`0 0 312 ${height * size + size * gap}`}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      aria-label="Carregando"
      style={{ display: 'block', margin: '0 auto' }}
    >
      {Array(size)
        .fill(null)
        .map((_, i) => {
          const y = height * i + gap * i;
          return (
            <rect
              key={`content-loader-rect-${i}`}
              x="1"
              y={y}
              rx="5"
              ry="5"
              width="312"
              height={height}
            />
          );
        })}
    </ContentLoader>
  );
}
