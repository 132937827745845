import { ButtonHTMLAttributes } from 'react';

import * as S from './styles';

type ButtonProps = {
  defaultSize: 'small' | 'medium';
} & ButtonHTMLAttributes<HTMLButtonElement>;

export function Button(props: ButtonProps) {
  const { children, defaultSize, ...restProps } = props;
  return (
    <S.Button size={defaultSize} {...restProps}>
      {children}
    </S.Button>
  );
}
