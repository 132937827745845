export function truncateText(text: string, length: number) {
  const newText = text.substring(0, length).trim();
  return text.length > length ? newText + '...' : newText;
}

export function formatToMoney(num: number = 0) {
  if (num !== 0) {
    num /= 100;
  }

  return `${num.toLocaleString('pt-br', {
    minimumFractionDigits: 2
  })}`;
}

export function compareTwoStrings(string1: string, string2: string) {
  const str1 = string1
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .trim();
  const str2 = string2
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .trim();
  const regex = new RegExp(str1, 'gi');
  return str2.match(regex);
}

export function compareVersion(x1: string | null, x2: string) {
  if (x1 === null) return false;

  const a = x1
    .replace('-skip', '')
    .split('.')
    .map((x) => x.padStart(2, '0'))
    .join('.');
  const b = x2
    .replace('-skip', '')
    .split('.')
    .map((x) => x.padStart(2, '0'))
    .join('.');
  return a.localeCompare(b);
}

export function groupByNumberOfElements(
  array: any[],
  numberOfElements: number
) {
  let counter = 0;
  const newArray = array.reduce(
    (accumulator: any[][], element: any) => {
      if (counter % numberOfElements === 0 && counter !== 0) {
        accumulator.push([]);
      }

      accumulator[accumulator.length - 1].push(element);
      counter++;
      return accumulator;
    },
    [[]]
  );

  return newArray;
}
