import api from '..';
import { ReportMode } from '../../types/Reports';

export function getManagementGeneralInformationV2(
  restaurantId: string,
  shift: ReportMode,
  since: Date,
  until: Date,
  shiftIndex: number[] | null
) {
  return api.getManagementGeneralInformationV2(
    restaurantId,
    shift,
    since,
    until,
    shiftIndex
  );
}
