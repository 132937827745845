import { BillFilters, RestaurantOperationScope } from '@cubos/zak-manager-api';
import api from '..';
import { ReportMode } from '../../types/Reports';

export function getReportProductSoldWithDiscountsAndTips(
  restaurantId: string,
  shift: ReportMode,
  scopeMode: RestaurantOperationScope | null,
  since: Date,
  until: Date,
  filters: BillFilters
) {
  return api.getReportProductSoldWithDiscountsAndTips(
    restaurantId,
    shift,
    scopeMode,
    since,
    until,
    filters
  );
}
