import styled from 'styled-components';

export enum LabelSizes {
  medium = '10px',
  small = '8px'
}

export enum ValueIndicatorLabels {
  money = 'R$',
  total = 'TOTAL'
}

interface LabelProps {
  size?: LabelSizes;
}

export const Wrapper = styled.div``;

export const Label = styled.span<LabelProps>`
  font-style: normal;
  font-weight: normal;
  font-size: ${({ size = LabelSizes.medium }) => size};
  line-height: 11px;
  color: #8c8c8c;
  margin-right: 8px;
`;

export const Value = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #262626;
`;

export const MoneyLoader = styled.span`
  width: 70px;
  height: 15px;
  background-color: #f3f3f3;
  display: block;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #ecebeb, #f6f6f6, transparent);
    animation: loading 1s infinite;
  }

  @keyframes loading {
    100% {
      transform: translateX(100%);
    }
  }
`;
