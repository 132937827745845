import styled from 'styled-components';

export const InstallApp = styled.div`
  width: 100%;
  height: 210px;
  padding: 1rem;
  background-color: #e10059;
  color: #fff;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 1.8rem;
    text-align: center;
  }

  button {
    width: 200px;
    padding: 1rem;
    border: 0;
    background-color: #fff;
    border-radius: 15px;
    margin-top: 1.5rem;
  }
`;

export const Close = styled.span`
  position: absolute;
  top: 15px;
  right: 15px;
  border: 0;
  background-color: transparent;
`;
