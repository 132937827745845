import styled, { css } from 'styled-components';

type ButtonProps = {
  isActive: boolean;
};

type GroupProps = {
  width?: string;
};

export const Group = styled.div(
  (props: GroupProps) => css`
    width: ${props.width || '312px'};
    height: 40px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    overflow: hidden;
  `
);

export const Button = styled.button<ButtonProps>`
  flex: 1;
  background-color: #ffffff;
  border: 0;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;

  ${(props) =>
    props.isActive &&
    css`
      border: 0;
      background: #e10059;
      box-shadow: 0px 1px 12px rgba(225, 0, 89, 0.5);
      color: #fff;
      border-radius: 8px;
    `}
`;
