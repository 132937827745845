import React, { useState } from 'react';
import { Wrapper } from './styles';

interface IChangeEvent {
  value: boolean;
  name: string;
}

interface IProps {
  text: {
    inactiveValue: string;
    activeValue: string;
  };
  value: boolean;
  onChange?: ({ value, name }: IChangeEvent) => void;
  name?: string;
}

export const ZakToggle = ({
  text,
  onChange,
  value,
  name = 'unknow'
}: IProps) => {
  const [hasActive, setHasActive] = useState<boolean>(value);

  React.useEffect(() => {
    if (onChange) {
      onChange({ value: hasActive, name });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasActive]);

  return (
    <Wrapper onClick={() => setHasActive(!hasActive)}>
      <div
        className={`zakToggleButton ${
          !hasActive ? 'zakToggleButton_hasActive' : ''
        } `}
      >
        {text.inactiveValue}
      </div>
      <div
        className={`zakToggleButton ${
          hasActive ? 'zakToggleButton_hasActive' : ''
        } `}
      >
        {text.activeValue}
      </div>
    </Wrapper>
  );
};
