import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { getManagementGeneralInformationV2 } from '../api/methods/getManagementGeneralInformationV2';

import { FinancialInfoV2 } from '../types/FinancialInfo';
import { ReportMode } from '../types/Reports';
import { UrlParams } from '../types/UrlParams';

export function useFetchManagementGeneralInformation() {
  const [managementGeneralInformation, setManagementGeneralInformation] =
    useState<FinancialInfoV2>();
  const [isLoading, setIsloading] = useState(true);
  const params = useParams<UrlParams>();

  function getManagementGeneralInformationV2FromApi(
    shift: ReportMode,
    since: Date,
    until: Date,
    shiftIndex: number[] | null = null
  ) {
    getManagementGeneralInformationV2(
      params.restaurantId,
      shift,
      since,
      until,
      shiftIndex
    )
      .then((response) => {
        setManagementGeneralInformation(response);
      })
      .catch((error) => {
        console.log('error on getManagementGeneralInformationV2', error);
      })
      .finally(() => {
        setIsloading(false);
      });
  }

  return {
    managementGeneralInformation,
    getManagementGeneralInformationV2FromApi,
    isLoading
  };
}
