import React from 'react';

export function NextArrowIcon() {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.91429 11.7489C0.753601 11.5881 0.66333 11.3701 0.66333 11.1429C0.66333 10.9156 0.753601 10.6976 0.91429 10.5369L5.29686 6.15171C5.31682 6.13181 5.33265 6.10816 5.34345 6.08213C5.35426 6.05609 5.35982 6.02818 5.35982 6C5.35982 5.97181 5.35426 5.9439 5.34345 5.91787C5.33265 5.89183 5.31682 5.86819 5.29686 5.84828L0.91429 1.46314C0.766377 1.30025 0.68677 1.08671 0.691951 0.866746C0.697132 0.646779 0.786704 0.437229 0.942121 0.281481C1.09754 0.125733 1.3069 0.0357159 1.52685 0.0300679C1.74681 0.0244199 1.96051 0.103573 2.12372 0.25114L6.96315 5.09057C7.08262 5.20997 7.1774 5.35174 7.24207 5.50778C7.30673 5.66383 7.34002 5.83108 7.34002 6C7.34002 6.16891 7.30673 6.33616 7.24207 6.49221C7.1774 6.64825 7.08262 6.79003 6.96315 6.90943L2.12372 11.7489C1.96309 11.9088 1.74566 11.9985 1.519 11.9985C1.29235 11.9985 1.07492 11.9088 0.91429 11.7489Z"
        fill="white"
      />
    </svg>
  );
}
