import { useHistory } from 'react-router';

import { IconButton } from '../IconButton';
import { ReturnIcon } from '../SVGs';

import * as S from './styles';

export interface ZakPageTitleProps {
  title: string;
  pushBackUrl: string;
}

export function ZakPageTitle(props: ZakPageTitleProps) {
  const router = useHistory();

  return (
    <S.Wrapper>
      <IconButton onClick={() => router.push(props.pushBackUrl)}>
        <ReturnIcon />
      </IconButton>
      <h1>{props.title}</h1>
    </S.Wrapper>
  );
}
