import styled from 'styled-components';

export const Wrapper = styled.div`
  & > p {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 10px;
    text-transform: uppercase;
    color: #8c8c8c;

    & > span {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 12px;
      color: #52557e;
    }

    .spacing-left {
      margin-left: 8px;
    }
  }

  width: 100%;
  max-width: 312px;
  border-bottom: 0.5px solid #d9d9d9;
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
