import styled from 'styled-components';

export const Card = styled.div`
  width: 312px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  position: relative;

  figure {
    width: 301px;
    height: 140px;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0px -1px 8px #dadada;
    overflow: hidden;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
    }
  }
`;

export const TitleContainer = styled.h2`
  width: 312px;
  height: 40px;
  padding: 0 20px;
  background: linear-gradient(360deg, #35385d 0%, #52557e 100%);
  border-radius: 8px;
  position: absolute;
  bottom: -30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #ffffff;

    &:last-of-type {
      font-size: 8px;
      line-height: 11px;
    }
  }
`;
