import styled from 'styled-components';

export const ToggleFilterWrapper = styled.div`
  max-width: 312px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 24px;
`;

export const CardContainer = styled.div`
  footer {
    margin: 3rem 0;
    display: flex;
    justify-content: space-between;
  }

  button {
    width: 45%;
    border: 0;
    padding: 12px 24px;
    font-weight: bold;
    background: #e10059;
    box-shadow: 0px 1px 7px rgb(225 0 89 / 50%);
    border-radius: 8px;
    color: white;

    &:disabled {
      background-color: #ddd;
      box-shadow: 0px 0px 0px rgb(225 0 89 / 50%);
    }
  }
`;
