/* eslint-disable react-hooks/rules-of-hooks */
import api from '../api';
import { useState } from 'react';

export function organization() {
  const [currentOrg, setCurrentOrg] =
    useState<api.RestaurantsByOrganization | null>(null);

  const [orgList, setOrgList] = useState<api.RestaurantsByOrganization[]>([]);
  const [restaurantRangeFilter, setRestaurantRangeFilter] = useState<
    'today' | 'yesterday'
  >('today');
  const [shiftMode, setShiftMode] = useState<'fiscal' | 'managerial'>(
    'managerial'
  );
  const [rangeDateDefaults, setRangeDateDefaults] = useState<
    'yesterday' | 'today' | 'last7Days' | 'last14Days' | 'last30Days'
  >('today');

  function changeShiftMode(val: 'fiscal' | 'managerial') {
    setShiftMode(val);
  }

  function changeRangeDateDefaults(val: any) {
    setRangeDateDefaults(val);
  }

  function changeCurrentOrg(data: api.RestaurantsByOrganization) {
    setCurrentOrg(data);
  }

  function changeOrgList(data: api.RestaurantsByOrganization[]) {
    setOrgList(data);
  }

  return {
    currentOrg,
    changeCurrentOrg,
    orgList,
    changeOrgList,
    restaurantRangeFilter,
    setRestaurantRangeFilter,
    rangeDateDefaults,
    changeRangeDateDefaults,
    shiftMode,
    changeShiftMode
  };
}
