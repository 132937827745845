import styled, { css } from 'styled-components';

type InputProps = {
  error: boolean;
  size: 'small' | 'medium';
};

export const Wrapper = styled.div<InputProps>`
  width: ${(props) => (props.size === 'small' ? '148px' : '312px')};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  label {
    width: fit-content;
    color: #8c8c8c;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 8px;
  }

  input {
    background: #fff;
    border: 1px solid #8c8c8c;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 12px 16px;

    &:focus {
      outline: none;
      border: 1px solid #e10059;
    }

    &:disabled {
      opacity: 0.5;
    }

    ${(props) =>
      props.error &&
      css`
        outline: none;
        border: 1px solid #eb0707;
      `}
  }

  span {
    font-size: 10px;
    color: #eb0707;
    margin-top: 4px;
  }
`;
