import styled from 'styled-components';

interface HeaderProps {
  isScrolling: boolean;
}

export const Wrapper = styled.main`
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  position: relative;
`;

export const Header = styled.header<HeaderProps>`
  ${({ isScrolling }) =>
    isScrolling ? 'box-shadow: 0px 3px 12px rgba(147, 147, 147, 0.25);' : null}

  /* transition box-shadow */
  transition: box-shadow 0.4s;

  padding: 0 24px 12px 24px;
  width: 100%;
  max-width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Body = styled.div`
  padding: 4px 24px 24px 24px;
  width: 100%;
  max-width: 360px;
  flex: 1;
  overflow-y: auto;
`;
