type CloseIconProps = {
  fill?: string;
};

export function CloseIcon(props: CloseIconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.81826 8.20229C9.79166 8.17575 9.77055 8.14422 9.75614 8.10951C9.74174 8.0748 9.73432 8.03758 9.73432 8C9.73432 7.96242 9.74174 7.92521 9.75614 7.8905C9.77055 7.85579 9.79166 7.82426 9.81826 7.79772L15.0937 2.52229C15.2028 2.41686 15.2899 2.29076 15.3498 2.15132C15.4097 2.01189 15.4412 1.86192 15.4426 1.71018C15.4439 1.55843 15.415 1.40794 15.3575 1.26748C15.3 1.12703 15.2152 0.999429 15.1079 0.892123C15.0006 0.784817 14.8729 0.699957 14.7325 0.642493C14.592 0.585029 14.4416 0.556113 14.2898 0.557431C14.1381 0.55875 13.9881 0.590277 13.8487 0.650173C13.7092 0.710069 13.5831 0.797135 13.4777 0.906289L8.20226 6.18172C8.17572 6.20832 8.14419 6.22944 8.10948 6.24384C8.07477 6.25824 8.03756 6.26566 7.99998 6.26566C7.9624 6.26566 7.92518 6.25824 7.89047 6.24384C7.85576 6.22944 7.82423 6.20832 7.79769 6.18172L2.52226 0.906289C2.30672 0.698108 2.01803 0.582915 1.71838 0.585519C1.41872 0.588123 1.13208 0.708316 0.920184 0.920211C0.708289 1.13211 0.588096 1.41875 0.585492 1.7184C0.582889 2.01806 0.698082 2.30674 0.906263 2.52229L6.18169 7.79772C6.2083 7.82426 6.22941 7.85579 6.24381 7.8905C6.25822 7.92521 6.26563 7.96242 6.26563 8C6.26563 8.03758 6.25822 8.0748 6.24381 8.10951C6.22941 8.14422 6.2083 8.17575 6.18169 8.20229L0.906263 13.4777C0.698082 13.6933 0.582889 13.9819 0.585492 14.2816C0.588096 14.5813 0.708289 14.8679 0.920184 15.0798C1.13208 15.2917 1.41872 15.4119 1.71838 15.4145C2.01803 15.4171 2.30672 15.3019 2.52226 15.0937L7.79769 9.81829C7.82423 9.79168 7.85576 9.77057 7.89047 9.75617C7.92518 9.74176 7.9624 9.73435 7.99998 9.73435C8.03756 9.73435 8.07477 9.74176 8.10948 9.75617C8.14419 9.77057 8.17572 9.79168 8.20226 9.81829L13.4777 15.0937C13.6932 15.3019 13.9819 15.4171 14.2816 15.4145C14.5812 15.4119 14.8679 15.2917 15.0798 15.0798C15.2917 14.8679 15.4119 14.5813 15.4145 14.2816C15.4171 13.9819 15.3019 13.6933 15.0937 13.4777L9.81826 8.20229Z"
        fill={props.fill ?? '#8C8C8C'}
      />
    </svg>
  );
}
