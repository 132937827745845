import React from 'react';

import { IconButton } from '../IconButton';
import { UpdateTimeIcon } from '../SVGs';

import * as S from './styles';

export interface ZakLastUpdatedIndicatorProps {
  now?: boolean;
  lastUpdate?: string;
  reload: () => void;
}

export function ZakLastUpdatedIndicator(props: ZakLastUpdatedIndicatorProps) {
  return (
    <S.Wrapper>
      {props.now ? (
        <p>
          <span>agora</span>
        </p>
      ) : (
        <p>
          última atualização
          <span className="spacing-left">{props.lastUpdate}</span>
        </p>
      )}
      <IconButton onClick={props.reload}>
        <UpdateTimeIcon />
      </IconButton>
    </S.Wrapper>
  );
}
