import styled from 'styled-components';

export const Wrapper = styled.form`
  background: linear-gradient(180deg, #4a4a4a 0%, #262626 100%);
  color: #fff;
  width: 100%;
  height: 100vh;
  padding: 3rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .error {
    font-size: 14px;
    line-height: 14px;
    color: #eb0707;
    margin-bottom: 2rem;
  }
`;

export const Header = styled.header`
  h1 {
    font-weight: bold;
    font-size: 16px;
    margin: 32px 0 8px 0;
  }

  h2 {
    font-size: 12px;
  }
`;

export const Fields = styled.div`
  height: 264px;
  margin-top: 32px;

  label {
    color: #fff;
  }

  div {
    margin-bottom: 16px;
  }
`;

export const Footer = styled.footer`
  width: 312px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    background: #e10059;
    color: #fff;
  }

  a {
    padding: 1.5rem;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
  }
`;
