import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import api from '../../api';
import { useStores } from '../../stores';

import { ZakPageHeaderLayout } from '../../components/ZakPageHeaderLayout';
import {
  compareTwoStrings,
  groupByNumberOfElements
} from '../../utils/helpers';

import { ToggleFilterWrapper, CardContainer } from './styles';
import { ZakButtonGroup } from '../../components/ZakButtonGroup';

import { RestaurantCard } from './RestaurantCard';

type Params = {
  id: string;
};

export function RestaurantList() {
  const params = useParams<Params>();

  const { organizationStore } = useStores();

  const [dateFilter, setDateFilter] = useState<Date>(new Date());
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (!organizationStore.currentOrg) {
      getCurrentRestaurant();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getCurrentRestaurant() {
    try {
      const data = await api.getRestaurantsByOrganization();
      const currentOrg = data.find((r) => r.id === params.id);

      organizationStore.changeOrgList(data);
      if (currentOrg) {
        organizationStore.changeCurrentOrg(currentOrg);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const filteredRestaurants = useMemo(() => {
    return (organizationStore.currentOrg?.restaurants ?? []).filter((rest) =>
      compareTwoStrings(searchValue, rest.info.name ?? '')
    );
  }, [searchValue, organizationStore.currentOrg?.restaurants]);

  const groupedRestaurants = useMemo(
    () =>
      groupByNumberOfElements(
        filteredRestaurants
          .sort((a, b) => a.info.name.localeCompare(b.info.name))
          .map((restaurant) => {
            return restaurant;
          }),
        8
      ),
    [filteredRestaurants]
  );

  return (
    <ZakPageHeaderLayout
      searchInputProps={{
        onChange: (e) => setSearchValue(e.target.value),
        value: searchValue
      }}
      genericHeaderComponent={
        <ToggleFilterWrapper>
          <ZakButtonGroup
            width={'148px'}
            activeKey={organizationStore.restaurantRangeFilter}
            list={[
              {
                key: 'today',
                value: 'Hoje'
              },
              {
                key: 'yesterday',
                value: 'Ontem'
              }
            ]}
            onChange={(val) =>
              organizationStore.setRestaurantRangeFilter(() => {
                /**
                 * date filter começa como hoje
                 * se val for igual a 'yesterday' eu retiro 1 dia desta data
                 */
                const newDateFilter = new Date();
                if (val === 'yesterday') {
                  newDateFilter.setDate(newDateFilter.getDate() - 1);
                }
                setDateFilter(newDateFilter);
                return val;
              })
            }
          />
        </ToggleFilterWrapper>
      }
    >
      <CardContainer>
        {groupedRestaurants[currentPage - 1].map((restaurant: any) => {
          return (
            <RestaurantCard
              key={restaurant.id}
              dateFilter={dateFilter}
              id={restaurant.id}
              name={restaurant.info.name}
              state={restaurant.info.addressBlock?.state || 'Sem localização'}
              hasInfra={restaurant.hasInfra}
            />
          );
        })}

        {groupedRestaurants.length > 1 && (
          <footer>
            <button
              disabled={currentPage === 1}
              onClick={() => {
                setCurrentPage((old) => {
                  if (old <= 1) {
                    return 1;
                  }

                  return old - 1;
                });
              }}
            >
              Anterior
            </button>

            <button
              disabled={currentPage === groupedRestaurants.length}
              onClick={() => {
                setCurrentPage((old) => {
                  if (old === groupedRestaurants.length) {
                    return groupedRestaurants.length;
                  }

                  return old + 1;
                });
              }}
            >
              Próximo
            </button>
          </footer>
        )}
      </CardContainer>
    </ZakPageHeaderLayout>
  );
}
