import React from 'react';

import { IconButton } from '../IconButton';
import { useHistory } from 'react-router';
import {
  ZakGestorLogo,
  UserIcon,
  // NotificationIcon,
  MainMenuIcon
} from '../../components/SVGs';

import * as S from './styles';

interface ZakAppBarProps {
  onClickDrawer?: () => void;
  onClickMainMenu?: () => void;
  onClickNotification?: () => void;
}

export function ZakAppBar(props: ZakAppBarProps) {
  const router = useHistory();
  return (
    <S.Wrapper>
      <ZakGestorLogo onClick={() => router.push('/')} />
      <S.IconsWrapper>
        {props.onClickMainMenu && (
          <IconButton role="main-menu-button" onClick={props.onClickMainMenu}>
            <MainMenuIcon />
          </IconButton>
        )}

        {/* {props.onClickNotification && (
          <IconButton
            role="notification-button"
            onClick={props.onClickNotification}
          >
            <NotificationIcon />
          </IconButton>
        )} */}

        <IconButton role="drawer-button" onClick={props.onClickDrawer}>
          <UserIcon />
        </IconButton>
      </S.IconsWrapper>
    </S.Wrapper>
  );
}
