import styled from 'styled-components';

export const Wrapper = styled.nav`
  width: 100%;
  max-width: 360px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
`;

export const IconsWrapper = styled.div`
  width: 112px;
  display: inline-flex;
  gap: 16px;
  justify-content: flex-end;
`;
