import styled from 'styled-components';

export const Wrapper = styled.div`
  /* temporary */
  max-width: 312px;
  width: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > button {
    margin-right: 4px;
    display: flex;
  }
  & > h1 {
    color: #35385d;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
  }
`;
