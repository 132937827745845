import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';

import api from '../../api';
import { useStores } from '../../stores';
import {
  sendAmplitudeData,
  setAmplitudeUserId,
  setAmplitudeUserProperties
} from '../../utils/amplitude';
import { InputWithLabel } from '../../components/InputWithLabel';
import { InputPassword } from '../../components/InputPassword';
import { Button } from '../../components/Button';
import { ZakLogo } from '../../components/SVGs';

import * as S from './styles';

export function Login() {
  const { authStore } = useStores();

  const router = useHistory();

  const [group, setGroup] = useState('');
  const [user, setUser] = useState('');
  const [pass, setPass] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDefaultCredentials();
    sendAmplitudeData('zakgestor_user_onenter_loginpage');
  }, []);

  function getDefaultCredentials() {
    const credentials = localStorage.getItem('@zak-basic-credentials');

    if (credentials) {
      try {
        const parsedCredentials = JSON.parse(credentials);
        setGroup(parsedCredentials.group.username);
        setUser(parsedCredentials.user);
      } catch (error) {
        setGroup('');
        setUser('');
      }
    }
  }

  async function makeLogin(e: React.FormEvent) {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const data = await api.logIn(group, user, pass);

      sendAmplitudeData('zakgestor_user_onlogin_success', {
        group,
        user
      });

      // amplitude seting data
      setAmplitudeUserId(data.id);
      setAmplitudeUserProperties({
        name: data.name,
        email: data.email,
        username: data.username,
        group: data.group
      });

      authStore.login(data);
      document.title = `Zak Gestor | ${data.group.name}` ?? 'Zak Gestor';
      router.push('/');
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  }

  const buttonSubmitIsDisabled = useMemo(() => {
    return !user || !group || !pass;
  }, [group, user, pass]);

  return (
    <S.Wrapper onSubmit={makeLogin}>
      <S.Header>
        <ZakLogo />
        <h1>Gerenciamento de Restaurantes</h1>
        <h2>Faça o login para acessar sua conta no Dashboard.</h2>
      </S.Header>

      <S.Fields>
        <InputWithLabel
          label="Grupo"
          defaultSize="medium"
          value={group}
          onChange={(e) => setGroup(e.target.value)}
          disabled={loading}
          autoCapitalize="off"
        />
        <InputWithLabel
          label="Usuário"
          defaultSize="medium"
          value={user}
          onChange={(e) => setUser(e.target.value)}
          disabled={loading}
          autoCapitalize="off"
        />
        <InputPassword
          label="Senha"
          defaultSize="medium"
          value={pass}
          onChange={(e) => setPass(e.target.value)}
          disabled={loading}
          autoCapitalize="off"
        />
      </S.Fields>

      <span className="error">{error}</span>

      <S.Footer>
        <Link to="/login">Licença de Software</Link>
        <Button
          defaultSize="small"
          type="submit"
          disabled={buttonSubmitIsDisabled}
        >
          {loading ? 'Carregando' : 'Entrar'}
        </Button>
      </S.Footer>
    </S.Wrapper>
  );
}
