/* eslint-disable react-hooks/rules-of-hooks */
import api from '../api';
import { useState } from 'react';

type User = api.Manager & {
  name: string;
};

export function auth() {
  const [hasPermission, setHasPermission] = useState(false);
  const [user, setUser] = useState<User | undefined>(() => {
    const userString = localStorage.getItem('@zak-user');

    if (userString) {
      try {
        const parsedUser = JSON.parse(userString) as User;

        return parsedUser;
      } catch (error) {
        localStorage.removeItem('@zak-user');
      }
    }

    return undefined;
  });

  const isLogged = Boolean(user);

  function login(value: api.Manager) {
    const setLoginValues = () => {
      setUser(value);
      localStorage.setItem('@zak-user', JSON.stringify(value));
      localStorage.setItem(
        '@zak-basic-credentials',
        JSON.stringify({
          user: value.username,
          group: value.group
        })
      );
    };
    if (value?.managerRoleId) {
      api
        .getManagerRole(value.managerRoleId)
        .then((managerRole) => {
          value['name'] = managerRole?.name;
          setLoginValues();
        })
        .catch(() => {
          setLoginValues();
        });
    }
  }

  function logout() {
    setUser(undefined);
    localStorage.removeItem('@zak-user');
  }

  function filterRestaurantsByOganization(
    restaurantsByOrganization: api.RestaurantsByOrganization[]
  ) {
    if (user) {
      return restaurantsByOrganization
        .map((organization) => {
          return {
            ...organization,
            restaurants: organization.restaurants.filter((restaurant) =>
              Boolean(user.restaurantIds.find((id) => id === restaurant.id))
            )
          };
        })
        .filter((organization) => organization.restaurants.length)
        .sort((a, b) => a.name.localeCompare(b.name));
    } else {
      return restaurantsByOrganization;
    }
  }

  async function getPermissions() {
    if (user) {
      const response = await api.getManagerRole(user?.managerRoleId);
      setHasPermission(response.permissions.homeScope !== 'nothing');
    }
  }

  return {
    user,
    isLogged,
    login,
    logout,
    filterRestaurantsByOganization,
    hasPermission,
    getPermissions
  };
}
