import { CloseIcon } from '../../../components/SVGs/CloseIcon';
import { ZakButtonGroup } from '../../../components/ZakButtonGroup';
import { ZakToggle } from '../../../components/ZakToggle';

import * as S from './styles';

type FiltersProps = {
  shiftMode: 'fiscal' | 'managerial';
  changeShiftMode(val: 'fiscal' | 'managerial'): void;
  isOpen: boolean;
  closeFilter(): void;
  rangeDateDefaults: string;
  changeRangeDateDefaults(val: any): void;
  applyFilters(): void;
};

export function Filters(props: FiltersProps) {
  return (
    <>
      <S.Overlay onClick={props.closeFilter} isActive={props.isOpen} />
      <S.Filter isActive={props.isOpen}>
        <h2>Filtros</h2>

        <S.FilterContainer>
          <ZakToggle
            text={{ inactiveValue: 'Gerencial', activeValue: 'Fiscal' }}
            onChange={({ value }) =>
              props.changeShiftMode(value ? 'fiscal' : 'managerial')
            }
            value={props.shiftMode === 'fiscal'}
          />

          <ZakButtonGroup
            activeKey={props.rangeDateDefaults}
            list={[
              {
                key: 'yesterday',
                value: 'Ontem'
              },
              {
                key: 'today',
                value: 'Hoje'
              }
            ]}
            onChange={(val) => props.changeRangeDateDefaults(val)}
          />

          <ZakButtonGroup
            activeKey={props.rangeDateDefaults}
            list={[
              {
                key: 'last7Days',
                value: 'Últ. 7 dias'
              },
              {
                key: 'last14Days',
                value: 'Últ. 14 dias'
              },
              {
                key: 'last30Days',
                value: 'Últ. 30 dias'
              }
            ]}
            onChange={(val) => props.changeRangeDateDefaults(val)}
          />
        </S.FilterContainer>

        <S.CloseBtn onClick={props.closeFilter}>
          <CloseIcon />
        </S.CloseBtn>

        <S.AppyFiltersBtn
          onClick={() => {
            props.applyFilters();
            props.closeFilter();
          }}
        >
          Aplicar Filtros
        </S.AppyFiltersBtn>
      </S.Filter>
    </>
  );
}
