import {
  BillFilters,
  ReportSoldGeneral,
  RestaurantOperationScope
} from '@cubos/zak-manager-api';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getReportProductSoldWithDiscountsAndTips } from '../api/methods/getReportProductSoldWithDiscountsAndTips';
import { useStores } from '../stores';
import { ReportMode } from '../types/Reports';
import { UrlParams } from '../types/UrlParams';

export function useFetchReportProductSoldWithDiscountsAndTips() {
  const [
    reportProductSoldWithDiscountsAndTips,
    setReportProductSoldWithDiscountsAndTips
  ] = useState<ReportSoldGeneral>();
  const [isLoading, setIsloading] = useState(true);
  const params = useParams<UrlParams>();
  const { organizationStore } = useStores();

  const getReportProductSoldWithDiscountsAndTipsFromApi = useCallback(
    (
      shift: ReportMode,
      scopeMode: RestaurantOperationScope | null,
      since: Date,
      until: Date,
      filters: BillFilters
    ) => {
      setIsloading(true);
      getReportProductSoldWithDiscountsAndTips(
        params.restaurantId,
        shift,
        scopeMode,
        since,
        until,
        filters
      )
        .then((response) => {
          setReportProductSoldWithDiscountsAndTips(response);
        })
        .catch((error) => {
          console.log(
            'error on getReportProductSoldWithDiscountsAndTips',
            error
          );
        })
        .finally(() => {
          setTimeout(() => {
            setIsloading(false);
          }, 100);
        });
    },
    [params.restaurantId]
  );

  useEffect(() => {
    const since = new Date();
    const until = new Date();

    getReportProductSoldWithDiscountsAndTipsFromApi(
      organizationStore.shiftMode,
      null,
      since,
      until,
      { categoryId: null, subCategoryId: null, productId: null }
    );
  }, [
    getReportProductSoldWithDiscountsAndTipsFromApi,
    organizationStore.shiftMode
  ]);

  return {
    reportProductSoldWithDiscountsAndTips,
    getReportProductSoldWithDiscountsAndTipsFromApi,
    isLoading
  };
}
