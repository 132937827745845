import { InputHTMLAttributes, useState } from 'react';
import { InputWithLabel } from '../InputWithLabel/index';

import * as S from './styles';

type InputPasswordProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  defaultSize: 'small' | 'medium';
};

export function InputPassword(props: InputPasswordProps) {
  const [type, setStype] = useState('password');
  const { label, defaultSize, ...restProps } = props;

  return (
    <S.Wrapper>
      <InputWithLabel
        label={label}
        defaultSize={defaultSize}
        type={type}
        {...restProps}
      />

      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() =>
          setStype((oldState) => (oldState === 'text' ? 'password' : 'text'))
        }
      >
        <rect opacity="0.01" width="16" height="16" fill="black" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.99998 3.6297C4.94998 3.6297 2.30623 5.40626 1.00623 8.00001C2.30623 10.5938 4.94998 12.3703 7.99998 12.3703C11.05 12.3703 13.6937 10.5938 14.9937 8.00001C13.6937 5.40626 11.05 3.6297 7.99998 3.6297ZM11.4484 5.94689C12.2703 6.47033 12.9656 7.17345 13.489 8.00001C12.9656 8.82658 12.2703 9.52814 11.4484 10.0531C10.4156 10.7125 9.22342 11.0594 7.99998 11.0594C6.77654 11.0594 5.58435 10.711 4.55154 10.0531C3.72967 9.5297 3.03435 8.82658 2.51092 8.00001C3.03435 7.17345 3.72967 6.47189 4.55154 5.94689C4.60467 5.91251 4.65935 5.8797 4.71404 5.84689C4.5781 6.22033 4.5031 6.62345 4.5031 7.04376C4.5031 8.97501 6.06873 10.5406 7.99998 10.5406C9.93123 10.5406 11.4969 8.97501 11.4969 7.04376C11.4969 6.62345 11.4219 6.22033 11.2859 5.84689C11.3406 5.8797 11.3937 5.91251 11.4484 5.94689ZM7.99998 6.68908C7.99998 7.41408 7.41248 8.00001 6.68904 8.00001C5.96561 8.00001 5.37811 7.41251 5.37811 6.68908C5.37811 5.96564 5.96561 5.37814 6.68904 5.37814C7.41248 5.37814 7.99998 5.96408 7.99998 6.68908Z"
          fill={type === 'password' ? '#4A4A4A' : '#E10059'}
        />
      </svg>
    </S.Wrapper>
  );
}
