import { useHistory } from 'react-router';

import api from '../../../api';
import { ZakGestorLogo } from '../../../components/SVGs';
import { useStores } from '../../../stores';

import * as S from './styles';

export function OrganizationCard(props: api.RestaurantsByOrganization) {
  const router = useHistory();
  const { organizationStore } = useStores();

  function openOrgDetails() {
    organizationStore.changeCurrentOrg(props);
    router.push(`organization/${props.id}`);
  }

  return (
    <S.Card onClick={openOrgDetails}>
      <figure>
        {props.logo ? (
          <img src={props.logo} alt={props.name} />
        ) : (
          <ZakGestorLogo />
        )}
      </figure>

      <S.TitleContainer>
        <span>{props.name}</span>
        <span>{props.restaurants.length} restaurantes</span>
      </S.TitleContainer>
    </S.Card>
  );
}
